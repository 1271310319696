import React, { useState, useEffect } from 'react';
import Aux from '../../hoc/Aux/Aux';
import ProjectListItemPlaceholder from '../../components/UI/Placeholder/ProjectListItemPlaceholder/ProjectListItemPlacholder';
import CallToActionPlaceholder from "../../components/UI/Placeholder/CallToActionPlaceholder/CallToActionPlaceholder";
import ProjectListItem from './ProjectListItem/ProjectListItem';
import CallToAction from '../../components/CallToAction/CallToAction';
import styles from './ProjectList.module.scss';
import axios from '../../axios-base';
import { useLocation, useHistory, NavLink } from 'react-router-dom';
import {Row, Col, Input, Label} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {default as CustomInput}  from '../../components/UI/Input/Input';
import qs from 'qs';
import ReactJoyride, {STATUS} from 'react-joyride';
import Cookies from "js-cookie";
import {Helmet} from "react-helmet";
const {determineVotes} = require('../../shared/VoteEvaluatorFunctions');

function ProjectListHook(props) {

    const [projects, setProjects] = useState(null);
    const [phrase, setPhrase] = useState('');
    const [categoryOptions, setCategoryOptions] = useState(null);
    const [categorySelected, setCategorySelected] = useState('');
    const [cta, setCta] = useState(null);
    const [steps, setSteps] = useState([]);

    const location = useLocation();
    let history = useHistory();

    useEffect(() => {
        const cta = axios.get('/cta/',{params: {page: 'projekte'}})
        const options = axios.get('/options/')

        Promise.all([cta, options]).then(([resCta, resOptions]) => {
            setCta(resCta.data[0]);

            let availableCategories = resOptions.data[0].projectForm.projectCategory;
            if(availableCategories && availableCategories.elementConfig.options.length > 1) {
                let firstOption = () => {
                    if(availableCategories.dependent){
                        return {value: "", displayValue: "Alle"}
                    } else {
                        return {value: "", displayValue: "Wählen Sie eine " + availableCategories.elementConfig.label, disabled: true}
                    }
                }

                availableCategories.elementConfig.options.unshift(firstOption());
                availableCategories.elementConfig.label = "Wählen Sie eine " + availableCategories.elementConfig.label;
                setCategoryOptions(availableCategories);
                setSteps([{
                    target: '.category-select',
                    content: `Über das Dropdown-Menü können Sie Ihre Auswahl jederzeit anpassen. ${availableCategories.elementConfig.label } wie z.B. ${availableCategories.elementConfig.options[1].displayValue} oder ${availableCategories.elementConfig.options[2].displayValue}`,
                    disableBeacon: true,
                    placement: 'top'
                }]);
            } else {
                setSteps([{
                    target: '.project-search',
                    content: 'Suchen Sie nach Ihren gewünschten Projekten',
                    disableBeacon: true,
                    placement: 'top'
                }]);
            }
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    useEffect(() => {
        const query = qs.parse(location.search.slice(1)).category;
        axios.get(`/projects/?category=${query}`)
            .then(response => {
                setProjects(response.data);
                setCategorySelected(query !== undefined ?  query : "");
            })
            .catch(error => {
                console.log(error);
            });
    }, [location])

    const categoryHandler = (e) => {
        const selectedCategory = e.target.value;
        setPhrase('');
        if(selectedCategory !== categorySelected){
            setCategorySelected(selectedCategory);
            history.push({
                search: `?category=${selectedCategory}`
            });
        }
    };

    const onChangeHandler = (e) => {
        setPhrase(e.target.value);
        if (categorySelected !== '' && categoryOptions.dependent) {
            setCategorySelected('');
            history.push({
                search: `?category=`
            });
        }
    };

        let categoryForm;
        if (categoryOptions){
            categoryForm = (
                <CustomInput
                    wrapperCSSClass='category-select mb-3'
                    elementType={categoryOptions.elementType}
                    elementConfig={categoryOptions.elementConfig}
                    value={categorySelected}
                    changed={(e) => categoryHandler(e)}
                    shouldValidate={categoryOptions.valid} />
            );
        }

        let projectsRender = (
            <Aux>
                <ProjectListItemPlaceholder />
                <ProjectListItemPlaceholder />
                <ProjectListItemPlaceholder />
            </Aux>
        );

        if (projects && props.votingPhases) {
            projectsRender = projects.reduce((displayedProjects, project) => {
                let projectVoteCount = 0;
                let projectRank = '-';
                if (project){
                    projectRank = project.rank;
                    projectVoteCount = determineVotes(project.voteCount, props.votingPhases);
                }

                let display = "d-none";
                const searchField = project.contestantName.toLowerCase() + " " + project.projectTitle.toLowerCase();
                if(searchField.includes(phrase.toLowerCase())){
                    display = "";
                }
                let projectLink = (
                    // Todo: Deep-Link zur Ressource definieren und Pretty-Link erstellen
                    <ProjectListItem
                        key={project._id}
                        display={display}
                        image={project.projectGallery.length ? project.projectGallery[0] : 'https://s3.eu-central-1.amazonaws.com/media.new-vereinsfoerderung.de/newcrowdvoting2023-platzhalter-rgb.jpg'}
                        rank={projectRank}
                        name={project.contestantName}
                        title={project.projectTitle}
                        shortDescription={project.projectShortDescription}
                        voteCount={projectVoteCount}
                        imageSrc={project.projectGallery[0]}
                        projectBalance={project.fundingAccount[0].balance}
                        threshold1={props.threshold1}
                        threshold2={props.threshold2}
                        fundingIncrement1={props.fundingIncrement1}
                        fundingIncrement2={props.fundingIncrement2}
                        phases={props.votingPhases}
                    >
                        {/* @Todo: Deep-Link zur Ressource definieren und Pretty-Link erstellen */}
                        <NavLink className={`stretched-link ${styles.ProjectListLink}`} to={'./' + project._id} key={project._id}/>
                    </ProjectListItem>
                );
                displayedProjects.push(projectLink);
                return displayedProjects;
            }, []);
        }

        let ctaRender = <CallToActionPlaceholder/>;

        if (cta){
            ctaRender = <CallToAction
                headline={cta.headline}
                copy={cta.copy}
                buttonText={cta.buttonText}
                buttonColor={cta.buttonColor}
                linkUrl={cta.linkUrl}
                outline={cta.outline}
                buttonVisibility={cta.buttonVisibility}
            />
        }

        return (
            <Aux>
                <Helmet>
                    <title>{`${process.env.REACT_APP_PROJECT_TITLE}: Teilnehmer`}</title>
                </Helmet>
                {Cookies.get('bigfoot_cookie-consent') && !Cookies.get('bigfoot_tour-projects') && <ReactJoyride
                    steps={steps}
                    disabledScrolling
                    hideCloseButton
                    scrollDuration={800}
                    styles={{
                        options: {
                            zIndex: 1021,
                            primaryColor: styles.primaryColor,
                        },
                        tooltipContent: {
                            padding: '0px'
                        }
                    }}
                    locale={{back: 'Zurück', close: 'Schließen', last: 'Letzte', next: 'Nächste', open: 'Öffnen', skip: 'Überspringen'}}
                    callback={({ status }) => {
                        if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {
                            Cookies.set('bigfoot_tour-projects', true, {expires: 180});
                        }
                    }}
                    floaterProps = {{
                        disableAnimation: true,
                        disableFlip: true}} /> }

                {/* CallToAction */}
                <Row className="my-3">
                    <Col>
                        {ctaRender}
                    </Col>
                </Row>
                <Row >
                    <Col className="mb-3 project-search">
                        <Label for="Teilnehmer suchen">Suchen Sie nach einem Teilnehmernamen</Label>
                        <div className={styles.SearchWrapper + " position-relative"}>
                            <FontAwesomeIcon className={styles.SearchIcon} icon={faSearch}/>
                            <Input onChange={onChangeHandler} className={styles.SearchField + " me-sm-2"} placeholder="Teilnehmer suchen" type="search" aria-label="Teilnehmer suchen" value={phrase}/>
                        </div>
                    </Col>
                    {categoryForm}
                </Row>
                <Row className="row-cols-1 row-cols-md-3">
                    {projectsRender}
                </Row>
            </Aux>
        );

}

export default ProjectListHook;
