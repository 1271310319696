import React, {useState, useEffect} from 'react';
import Aux from '../../hoc/Aux/Aux';
import ProjectPlaceholder from '../../components/UI/Placeholder/ProjectPlaceholder/ProjectPlaceholder'
import Gallery from '../../components/Gallery/Gallery';
import GalleryItem from '../../components/Gallery/GalleryItem/GalleryItem';
import ShareButton from '../../components/UI/SocialShareButton/SocialShareButton';

import {Row, Col} from 'reactstrap';
import axios from '../../axios-base';
import moment from 'moment';
import SMSVote from "./SMSVote";
import FingerprintVote from "./FingerprintVote";
import VotePlaceholder from './VotePlaceholder';
import {Helmet} from "react-helmet";
import {useParams} from 'react-router-dom';
import ProgressBar from "../../components/UI/ProgressBar/ProgressBar";
import classes from "../ProjectList/ProjectListItem/ProjectListItem.module.scss";
import {determineVotes} from "../../shared/VoteEvaluatorFunctions"
function Project(props){

    const [loadedPost, setLoadedPost] = useState(null);
    const [voteCount, setVoteCount] = useState(null);

    let { id } = useParams();
    useEffect(() => {
        axios.get(`projects/${id}`)
            .then(({data}) => {
                let votes = determineVotes(data.voteCount, props.votingPhases);
                setLoadedPost(data)
                setVoteCount(votes);
            })
            .catch(error => {
                console.log(error);
            });
    }, [props.votingPhases, id])

        let voteComponent;
        if (loadedPost && props?.votingPhases) {
            const startDate = props.votingPhases.voteStart;
            if (!props.votingPhases.votingStarted) {
                // placeholder - prior to voting
                let text = 'Am ' + moment(startDate).format('DD.MM.') + ' geht\'s los';
                voteComponent = <VotePlaceholder text={text} />;
            } else if (props.votingPhases.votingEnded) {
                // placeholder - end of voting
                voteComponent = <VotePlaceholder text='Abstimmung ist beendet!' />;
            } else {
                // voting in progress
                if(props.balance > 0) {
                    voteComponent = <SMSVote projectCategory={loadedPost.projectCategory} {...props}/>;
                } else {
                    voteComponent = <VotePlaceholder text='Der Fördertopf ist leer'/>;
                }
            }
        }

        let project = (
            <Row className="py-3">
                <Col xs="12">
                    <ProjectPlaceholder/>
                </Col>
            </Row>
        );

        if (loadedPost) {

            project = (
                <Aux>
                    <Helmet>
                        <title>{`${process.env.REACT_APP_PROJECT_TITLE}: ${loadedPost.contestantName}`}</title>
                    </Helmet>
                    <Row className="my-3">
                        <Col xs="12" lg="5">
                            <Row className="pb-3 pt-1">
                                <Col>
                                    {loadedPost && loadedPost.projectGallery.length ? <div className={classes.CardImage} style={{ backgroundImage: `url(${loadedPost.projectGallery[0]})` }}/> : <div className={classes.CardImage} style={{ backgroundImage: `url(https://s3.eu-central-1.amazonaws.com/media.new-vereinsfoerderung.de/newcrowdvoting2023-platzhalter-rgb.jpg)` }}/>}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h1 className="text-primary">{loadedPost.contestantName}</h1>
                                    <h2 className="text-dark">{loadedPost.projectTitle}</h2>

                                </Col>
                            </Row>
                            <Row>
                                <Col className="overflow-hidden">
                                    <ProgressBar
                                        cssClasses={"mt-5 mb-4"}
                                        voteCount={voteCount}
                                        threshold1={props.threshold1}
                                        threshold2={props.threshold2}
                                        fundingIncrement1={props.fundingIncrement1}
                                        fundingIncrement2={props.fundingIncrement2}
                                        projectBalance={loadedPost ? loadedPost.fundingAccount.balance : 0}
                                        bubble={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                {voteComponent}
                                <Col xs="12" sm="6" lg="12" className="mb-2">
                                    <Row>
                                        <Col xs="6" lg="4" className="pb-1 pe-1 ps-3 pt-0 ps-md-1 ps-lg-3">
                                            <ShareButton
                                                shareType="facebook"
                                                voteCount={voteCount}
                                                threshold1={props.threshold1}
                                                threshold2={props.threshold2}
                                                fundingIncrement1={props.fundingIncrement1}
                                                fundingIncrement2={props.fundingIncrement2}
                                            />
                                        </Col>

                                        <Col xs="6" lg="4" className="pb-1 pe-3 ps-1 pt-0 pe-md-1 pe-lg-3 d-md-none">
                                            <ShareButton
                                                shareType="whatsApp"
                                                voteCount={voteCount}
                                                threshold1={props.threshold1}
                                                threshold2={props.threshold2}
                                                fundingIncrement1={props.fundingIncrement1}
                                                fundingIncrement2={props.fundingIncrement2}
                                            />
                                        </Col>

                                        <Col xs="6" lg="4" className="pb-1 pe-1 ps-3 pt-0 ps-md-1">
                                            <ShareButton
                                                shareType="email"
                                                voteCount={voteCount}
                                                threshold1={props.threshold1}
                                                threshold2={props.threshold2}
                                                fundingIncrement1={props.fundingIncrement1}
                                                fundingIncrement2={props.fundingIncrement2}
                                            />
                                        </Col>

                                        <Col xs="6" lg="4" className="pb-1 pe-3 ps-1 pt-0 ps-md-1 pe-lg-3">
                                            <ShareButton
                                                shareType="twitter"
                                                voteCount={voteCount}
                                                threshold1={props.threshold1}
                                                threshold2={props.threshold2}
                                                fundingIncrement1={props.fundingIncrement1}
                                                fundingIncrement2={props.fundingIncrement2}
                                            />
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>

                        </Col>

                        <Col xs="12" lg="7">
                            <Row>
                                <Col xs={{size: 12, order: 2}} lg={{order: 1}}>
                                    <h1 className="text-primary">Das ist unser nachhaltiges Projekt</h1>
                                    <p>{loadedPost.contestantAnswer1}</p>
                                </Col>

                                <Col xs={{size: 12, order: 1}} lg={{order: 3}} className="px-0 p-md-3">
                                    <Gallery>
                                        {loadedPost.projectGallery.map((galleryItem, index) => (
                                            <GalleryItem
                                                key={index}
                                                src={galleryItem}
                                                alt={'Galeriebild ' + index}/>
                                        ))}
                                    </Gallery>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Aux>
            )
        }

        return (
            <Aux>
                {project}
            </Aux>
        );
    }

export default Project;
